import React, { useState } from 'react'
import './style.scss'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isAnyStorageLocationType } from 'constants/locationTypes'
import { isEmptyObject, date, dateToFormat } from 'helpers'
import Modal from 'react-modal'
import Button from 'components/common/Button'
import TextInput from 'components/common/TextInput'
import SelectInput from 'components/common/SelectInput'
import { updateWarehousePallet } from 'store/warehousePallet/actions'
import { updateWarehouseCase } from 'store/warehouseCase/actions'
import { warehouseCaseAndPalletInventoryAdjustment } from 'store/warehouseCaseAndPallet/actions'
import { logInventoryAdjustment } from 'store/log/actions'
import { REGEX } from 'constants/regex'
import { MODAL_INVENTORY_ADJUSTMENT, MODAL_CHOOSE_COPIES } from 'components/common/Modal'
import { openPrintDialog } from 'store/print/actions'
import Print from 'components/common/Print'
import { PALLET_LABELS, CASE_UPC } from 'constants/print'
import { showModal, closeModal } from '../../../../store/modal/actions'

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.67)',
    zIndex: 1000,
  },
}
const ModalInvAdjustment = (props) => {
  const {
    isOpen,
    modal,
    reasonList,
    showModal,
    closeModal,
    openPrintDialog,
    inventoryAdjustment,
  } = props

  if (!isOpen) {
    return ''
  }

  const { events, options = {} } = modal

  if (isEmptyObject(options)) {
    return ''
  }

  const [palletsObjAry, setPalletsObjAry] = useState(
    options.item.pallets ? options.item.pallets : [],
  )

  const [caseQty, setCaseQty] = useState(options.item.location_case_qty)
  const [adjReason, setAdjReason] = useState(0)

  const maxValue = options.item.location_case_capacity
  const isAnyStorage = isAnyStorageLocationType(options.item)

  const onChangeSelectInput = (name, value) => setAdjReason(value)
  const onChangeTextInput = (name, value) =>
    setCaseQty(!value ? 0 : parseInt(value))

  // each time you change a pallet field in the modal
  const onChangeMultiField = (e, palletInModal) => {
    const fieldQuantity = parseInt(e.target.value)
    // TODO - Refactor this statement to make it more readable.
    // set the palletsObjAry, which is used as the display data
    setPalletsObjAry(
      !fieldQuantity // if no value/number in that input field (because hit backspace, etc)
        ? (currentPalletsObjAry) =>
            // map the currentPalletsObjAry, and for each singlePalletObj
            currentPalletsObjAry.map(
              (singlePalletObj) =>
                singlePalletObj.id === palletInModal.id // if the id of the singlePalletObj matches the id of the modal field
                  ? { ...singlePalletObj, qty_cases_on_pallet: 0 } // then set that singlePalletObj's qty... to zero (and zero is therefore what is shown)
                  : singlePalletObj, // else, just leave the data as is (i.e. for the other fields that aren't being typed in)
            )
        : // else (i.e. if there is a value (i.e. number in that input field that's being changed)
          (currentPalletsObjAry) =>
            currentPalletsObjAry.map(
              (singlePalletObj) =>
                singlePalletObj.id === palletInModal.id //if the obj id matches the modal id
                  ? { ...singlePalletObj, qty_cases_on_pallet: fieldQuantity } //then for that one, change the qty to whatever was typed into the field
                  : singlePalletObj, // else, just leave the data as is (i.e. for the other fields that aren't being typed in)
            ),
    )
  }

  const onClickPrint = () => {
    const afterAction = () => {
      let idsToPrint = []

      options.item.pallets.forEach((palletOld) => {
        palletsObjAry.forEach((palletNew) => {
          if (palletOld.id === palletNew.id) {
            idsToPrint.push(palletNew.id)
          }
        })
      })

      if (idsToPrint.length > 0) {
        openPrintDialog({
          ids: idsToPrint,
          document: PALLET_LABELS,
          onPrintDialogClose: () => {
            events.onAccept()
          },
        })
      }
    }
    adjustInventory(afterAction)
  }

  const onAccept = () => {
    const afterAction = () => {
      events.onAccept()
    }
    adjustInventory(afterAction)
  }

  const adjustInventory = (afterAction) => {
    inventoryAdjustment({
      request: {
        data: {
          adjustment_reason: adjReason,
          warehouse_case: {
            id: options.item.warehouse_case_id,
            warehouse_location_id: options.item.warehouse_location_id,
            location_type_id: options.item.location_type_id,
            product_id: options.item.product_id,
            current_case_qty: options.item.location_case_qty,
            location_case_qty: caseQty,
            pallets: palletsObjAry,
          },
        },
      },
      afterAction: afterAction,
    })
  }

  const printCaseUpc = () => {
    const productToPrint = options.item.product_id

    showModal({
      name: MODAL_CHOOSE_COPIES,
      events: {
        onDeny: () => closeModal(MODAL_CHOOSE_COPIES),
        onAccept: (printQty) => {
          closeModal(MODAL_CHOOSE_COPIES)
          openPrintDialog({
            id: productToPrint,
            qty: printQty,
            document: CASE_UPC,
            onPrintDialogClose: () => {
              events.onAccept()
            },
          })
        },
      },
    })
  }

  const getButton = () => (
    <Button
      disabled={!adjReason}
      onClick={isAnyStorage ? onClickPrint : onAccept}
      text={isAnyStorage ? 'Print Label' : 'Save'}
    />
  )

  return (
    <>
      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        style={customStyles}
        className="modal--inv-adjustment modal--default "
      >
        <p className="title">Inventory Adjustment</p>

        {isAnyStorage ? (
          <>
            {palletsObjAry.map((pallet, index) => (
              <div className="row" key={index.toString()}>
                <span className="sub-title">New Case Qty</span>
                <div className="row__column">
                  <input
                    className="text-input"
                    id={pallet.id.toString()}
                    onChange={(e) => onChangeMultiField(e, pallet)}
                    pattern={REGEX.IS_INTEGER}
                    value={pallet.qty_cases_on_pallet}
                    name={'qty_cases_on_pallet'}
                  />
                  &nbsp;/&nbsp;{options.item.pallet_case_capacity}
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="row">
            <span className="sub-title">New Case Qty</span>
            <div className="row__column">
              <TextInput
                onChange={onChangeTextInput}
                pattern={REGEX.IS_INTEGER}
                value={caseQty}
                name="new-case-qty-input"
              />
              &nbsp;/&nbsp;{maxValue}
            </div>
          </div>
        )}

        <div className="row" style={{ marginTop: '15px' }}>
          <span className="sub-title">Adjustment Reason</span>
          <SelectInput
            name={'adj-reason-select'}
            value={adjReason}
            defaultValue="- Select A Reason - "
            items={reasonList}
            onChange={onChangeSelectInput}
            className="row__column"
          />
        </div>
        <div className="btn-menu">
          <Button onClick={printCaseUpc} text="Print Case UPC" />
          <Button onClick={events.onDeny} text="Cancel" />
          {getButton()}
        </div>
      </Modal>
      <Print />
    </>
  )
}

ModalInvAdjustment.propTypes = {
  isOpen: PropTypes.bool,

  modal: PropTypes.object,

  reasonList: PropTypes.array,

  updateWarehousePallet: PropTypes.func,
  updateWarehouseCase: PropTypes.func,
  logInventoryAdjustment: PropTypes.func,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === MODAL_INVENTORY_ADJUSTMENT,
  ),
  modal: state.modal.openModalsInfo.find(
    (item) => item.name === MODAL_INVENTORY_ADJUSTMENT,
  ),

  reasonList: state.reason.list,
})

const mapDispatchToProps = {
  updateWarehousePallet,
  updateWarehouseCase,
  logInventoryAdjustment,
  showModal,
  closeModal,
  openPrintDialog,
  inventoryAdjustment: warehouseCaseAndPalletInventoryAdjustment,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalInvAdjustment)
